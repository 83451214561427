<template>
  <div id="flow.inputs.water.index">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "flow.inputs.water.index",
  computed: {
    ...mapGetters({
      settings: "getFlowSettings",
    }),
  },
  created() {
    if (this.settings.stored.level === 'basic' && this.$router.currentRoute.name !== 'load') {
      this.$router.push({name: 'flow.load'})
    }
  }
};
</script>

<style>
</style>