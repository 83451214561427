<template>
  <div id="flow.settings">
    <b-modal
      id="modal-flow-settings"
      scrollable
      no-close-on-backdrop
      title="Settings"
      @ok="ok"
      @cancel="cancel"
    >
      <b-form-group class="mr-2">
        <label for="units-live">{{$t('flow.settings.level.label')}}:</label>
        <b-form-select
          id="level-live"
          v-model="$v.level.$model"
          :options="$t('flow.settings.level.options')"
          aria-describedby="level-feedback"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="mr-2">
        <label for="units-live">{{$t('flow.settings.units.label')}}:</label>
        <b-form-select
          id="units-live"
          v-model="$v.units.$model"
          :options="$t('flow.settings.units.options')"
          aria-describedby="units-feedback"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="mr-2">
        <label for="fluids-live">{{$t('flow.settings.fluids.label')}}:</label>
        <b-form-select
          id="fluids-live"
          v-model="$v.fluids.$model"
          :options="$t('flow.settings.fluids.options')"
          aria-describedby="fluids-feedback"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="mr-2">
        <label for="detail-live">{{$t('flow.settings.detail.label')}}:</label>
        <b-form-select
          id="detail-live"
          v-model="$v.detail.$model"
          :options="$t('flow.settings.detail.options')"
          aria-describedby="detail-feedback"
        ></b-form-select>
      </b-form-group>
      <template v-slot:modal-footer>
        <b-button type="button" variant="success" @click="ok()">
          <div>
            <i class="fas fa-save"></i>
            {{$t('flow.settings.buttons.save')}}
          </div>
        </b-button>
        <b-button
          type="button"
          variant="danger"
          @click="cancel()"
        >{{$t('flow.settings.buttons.cancel')}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "flow.settings",
  computed: {
    ...mapGetters({
      settings: "getFlowSettings",
    }),
    level: {
        get() {
            return this.settings.form.level;
        },
        set(value) {
            this.$store.commit("setFlowSettingsLevel", value)
        }
    },
    units: {
      get() {
        return this.settings.form.units;
      },
      set(value) {
        this.$store.commit("setFlowSettingsUnits", value)
      },
    },
    fluids: {
      get() {
        return this.settings.form.fluids;
      },
      set(value) {
        this.$store.commit("setFlowSettingsFluids", value);
      },
    },
    detail: {
      get() {
        return this.settings.form.detail;
      },
      set(value) {
        this.$store.commit("setFlowSettingsDetail", value);
      },
    },
  },
  validations: {
    level: {
      required
    },
    units: {
      required,
    },
    fluids: {
      required,
    },
    detail: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    ok() {
      this.$store.dispatch('storeFlowStoreSettings')
      if (this.settings.stored.level === 'basic' && this.$router.currentRoute.name !== 'load') {
        this.$router.push({name: 'flow.load'})
      }
      this.$root.$emit("bv::hide::modal", "modal-flow-settings");
    },
    cancel() {
      this.$root.$emit("bv::hide::modal", "modal-flow-settings");
    },
  },
};
</script>

<style>
</style>