<template>
  <div id="flow.components.printButton">
    <b-link
      class="m-0 p-0 text-muted"
      @click="openSettings()"
      :disabled="true"
    >
      <div class="h3 m-0 p-0">
        <i class="fas fa-print"></i>
      </div>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "flow.components.printButton",
  methods: {
    openSettings() {
      return false
    },
  },
};
</script>

<style>
</style>