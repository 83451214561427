<template>
  <div id="flow.components.questionButton">
    <b-link
      class="m-0 p-0 text-success"
      @click="openQuestions()"
    >
      <div class="h3 m-0 p-0">
        <i class="fas fa-question-circle"></i>
      </div>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "flow.components.questionButton",
  methods: {
    openQuestions() {
        this.$root.$emit("bv::show::modal", "modal-flow-help");
    },
  },
};
</script>

<style>
</style>