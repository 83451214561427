<template>
  <div id="flow.components.settingsButton">
    <b-link
      class="m-0 p-0 text-muted"
      @click="openSettings()"
    >
      <div class="h3 m-0 p-0">
        <i class="fas fa-cog"></i>
      </div>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "flow.components.settingsButton",
  methods: {
    openSettings() {
      this.$root.$emit("bv::show::modal", "modal-flow-settings");
    },
  },
};
</script>

<style>
</style>