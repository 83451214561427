<template>
  <div id="flow.help">
    <b-modal
      id="modal-flow-help"
      scrollable
      hide-footer
      no-close-on-backdrop
      size="xl"
    >
    <template #modal-title>
      <div v-if="settings.stored.level === 'basic' || help.active==='load'" class="text-center">Load help menu</div>
      <div v-else-if="settings.stored.level === 'expert' && help.active==='design'" class="text-center">Design help menu</div>
      <div v-else-if="settings.stored.level === 'expert' && help.active==='offDesign'" class="text-center">Off-design help menu</div>
    </template>
      <b-tabs card-header tabs class="mb-2 mt-0" v-if="settings.stored.level !== 'basic' && loadCases.length > 0">
        <b-tab v-on:click="setFlowHelpActive('load')" :active="isActive('load')" title="Load"></b-tab>
        <b-tab v-on:click="setFlowHelpActive('design')" :active="isActive('design')" title="Design"></b-tab>
        <b-tab v-on:click="setFlowHelpActive('offDesign')" :active="isActive('offDesign')" title="Off-design"></b-tab>
      </b-tabs>
      <b-container v-if="settings.stored.level === 'basic' || help.active==='load'">
          <h3>Flow velocities</h3>
          <hr>
          The velocity of the flow can be calculated based on the in- and outlet sizing of the valve body. <br>
          Sizing can be added by clicking on the checkbox "Add valve sizing" it is however not required and the calculation can also be done without. <br>
          There are also recommended velocity limits for water in the liquid, vapor or multiphase state. <br>
          A "(Very) High velocity" warning is given if either the inlet or outlet is above the recommend limit shown in the table below. <br>
          <br>
          <table class="table table-striped table-sm">
              <tr>
                  <th>Water state</th>
                  <th>Limit</th>
              </tr>
              <tr>
                  <td>Liquid</td>
                  <td>3 m/s</td>
              </tr>
              <tr>
                  <td>Vapour</td>
                  <td>60 m/s</td>
              </tr>
              <tr>
                  <td>Two-phase</td>
                  <td>40 m/s</td>
              </tr>
          </table>
          <br>
          <h3>Choking</h3>
          <hr>
          "Choking" or "choking flow" occurs when the amount of flow does not increase any further for a great drop in pressure. <br>
          A "Choking" warning is given if choking occurs in the valve. <br>
          However if a lower exit pressure is given the flow will still go to this pressure but it will have no impact on the flow. <br>
          Choking impose no direct danger to a valve and a proper valve can have a fulfilling life even if it chokes. <br>
          <br>
          <h3>Flashing</h3>
          <hr>
          "Flashing" can occur in valves operating close to the saturation temperature and pressure of the water. <br>
          Due to a pressure drop caused by the valve steam vapour can form. <br>
          A "Flashing" warning is given if flashing occurs after the valve. <br>
          A steam-water mixture outflow can result in high exit velocity which can be very corrosive due to the high velocity of the water droplets. <br>
          One should be carefull when working with such valves and aviod using elbow(s) or long piping after such a valve. <br>
          <br>
          <h3>Cavitation</h3>
          <hr>
          "Cavitation" can occur in a valve if at one point in the valve drop below the vapor pressure. <br>
          A "Caviation" warning is given if cavitation occurs in the valve.<br>
          One should try to avoid cavitation at all cost as it is bad news all around as it can badly damages any valve. <br>
      </b-container>
      <b-container v-else-if="settings.stored.level === 'expert' && help.active==='design'">
        <h2 class="text-center">Design help menu</h2>
      </b-container>
      <b-container v-else-if="settings.stored.level === 'expert' && help.active==='offDesign'">
        <h2 class="text-center">Off-design help menu</h2>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "flow.help",
  computed: {
    ...mapGetters({
      settings: "getFlowSettings",
      loadCases: "getFlowDesignLoadCases",
      designCases: "getFlowOffDesignDesignCase",
      help: "getFlowHelp",
    }),
  },
  methods: {
    ok() {
      this.$root.$emit("bv::hide::modal", "modal-flow-help");
    },
    cancel() {
      this.$root.$emit("bv::hide::modal", "modal-flow-help");
    },
    setFlowHelpActive(value) {
      this.$store.commit("setFlowHelpActive", value)
    },
    isActive(value) {
      return value === this.help.active
    }
  },
};
</script>

<style>
</style>