<template>
  <div id="flow.index">
    <b-row class="m-0">
      <b-col xl="12">
        <b-card header-tag="nav" class="mt-4 mb-4">
          <template v-slot:header>
            <b-navbar toggleable="sm" class="text-center">
              <b-navbar-brand href="#">
                <router-link class="text-dark" :to="{ name: 'flow.view' }">{{
                  $t("flow.title")
                }}</router-link>
              </b-navbar-brand>
              <b-navbar-nav v-if="settings.stored.level === 'expert'">
                <b-nav-item v-if="loadCases.length > 0">
                  <router-link :to="{ name: 'flow.load' }">{{$t('flow.nav.load')}}</router-link>
                </b-nav-item>
                <b-nav-item v-if="loadCases.length > 0">
                  <router-link :to="{ name: 'flow.design' }">{{$t('flow.nav.design')}}</router-link>
                </b-nav-item>
                <b-nav-item v-if="loadCases.length > 0 && designCases">
                  <router-link :to="{ name: 'flow.offDesign' }">{{$t('flow.nav.offDesign')}}</router-link>
                </b-nav-item>
              </b-navbar-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <print-button v-show="false" class="mr-3" />
                <question-button class="mr-3" />
                <settings-button />
              </b-navbar-nav>
            </b-navbar>
          </template>
          <inputs />
        </b-card>
      </b-col>
    </b-row>
    <help />
    <settings />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import inputs from './inputs'
import settingsButton from './components/settingsButton'
import printButton from './components/printButton'
import questionButton from './components/questionButton'
import help from "./help/index"
import settings from "./settings/index";
export default {
  name: "flow.index",
  components: {
      inputs,
      settingsButton,
      printButton,
      questionButton,
      help,
      settings
  },
  computed: {
    ...mapGetters({
      settings: "getFlowSettings",
      loadCases: "getFlowDesignLoadCases",
      designCases: "getFlowOffDesignDesignCase"
    }),
  },
  created() {
    const query = this.$route.query;
    this.$store.dispatch("getFlowStoreSettings");
    if (query["u"] !== undefined) {
      if (query["u"] === "imperial") {
        this.$store.dispatch("setFlowGlobalUnits", "imperial");
      } else if (query["u"] === "si") {
        this.$store.dispatch("setFlowGlobalUnits", "si");
      } else {
        this.$store.dispatch("setFlowGlobalUnits", "metric");
      }
    }
  },
};
</script>

<style>
</style>