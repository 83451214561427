<template>
  <div id="flow.inputs.index">
    <div v-if="settings.stored.fluids === 'water'">
      <water />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import water from "./water";
export default {
  name: "flow.inputs.index",
  components: {
    water,
  },
  computed: {
    ...mapGetters({
      settings: "getFlowSettings",
    }),
  },
};
</script>

<style>
</style>